@media screen and (min-width: 800px) {
    .tally{
        margin-top: 150px;
        max-width: 500px;
        min-height: 800px;
        max-height: 900px;
        overflow: scroll;
        margin: 0 auto;
        padding: 15px;
        background-color: black;
        border-radius: 15px;
    }
 }
.tally{
  min-height: 500px;
}
ul{
  padding-left: 0px;
}
li{
  padding-left: 0px;
  list-style: none !important;
}
button:focus{
  outline: 0 !important;
}
/* Facebook fix */
.public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: #aaaaaa;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 60px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 50px;
  }